.ReportsTable .btn {
    min-width: 50px;
}
.ReportsTable td {
    text-align: left;
}
.ReportsTable__row .ReportsTable__lastCol .btn:not(.btn-deleteReport, .btn-updateReport) {
    display: none;
}
.ReportsTable__row.report-ready .ReportsTable__lastCol .btn-downloadReport,
.ReportsTable__row.report-accounted .ReportsTable__lastCol .btn-downloadReport,
.ReportsTable__row.report-accounted .ReportsTable__lastCol .btn-toggleReportLvs {
    display: inline-block;
}
.ReportsTable__row.report-accounted .ReportsTable__lastCol .btn-updateReport {
    display: none;
}
.ReportsTable__lastCol {
    text-align: center;
    width: 70px;
}
.btn-downloadReport span {
    display: block;
    font-size: 10px;
}
.ReportsTable__col-nazev {
    width: 180px;
}
.ReportsTable__row {
    border-top: 1px solid lightgrey;
}
.LvTable {
    width: 100%;
    margin: 0;
    border-bottom: none;
}
.btn-updateLvs {
    display: none;
}
.lv-requests-sent .btn-updateLvs {
    display: inline-block;
}
.LvTable > tbody > tr {
    border: 1px solid transparent;
    border-top: 1px solid lightgray !important;
}
.btn-downloadLv span {
    font-size: 10px;
    display: block;
}